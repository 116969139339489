var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"modal_edit_detalle"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(" Editar Detalle Nominación ")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Ruta regalías")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$v.detalle.ruta_regalias_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"label":"nombre","placeholder":"Ruta","options":_vm.listasForms.rutasRegalias},on:{"input":function($event){return _vm.selectRuta()}},model:{value:(_vm.ruta),callback:function ($$v) {_vm.ruta=$$v},expression:"ruta"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Producto Líquido")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$v.detalle.producto_liquido_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"label":"nombre","placeholder":"Producto Líquido","options":_vm.listasForms.prodLiquidos},on:{"input":function($event){return _vm.selectProducto()}},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Solicitud")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.fecha_solicitud),expression:"detalle.fecha_solicitud"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.fecha_solicitud.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"date","min":_vm.$parent.form.fecha_inicial,"max":_vm.$parent.form.fecha_final},domProps:{"value":(_vm.detalle.fecha_solicitud)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "fecha_solicitud", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Cargue")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.fecha_cargue),expression:"detalle.fecha_cargue"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.fecha_cargue.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"date","min":_vm.$parent.form.fecha_inicial,"max":_vm.$parent.form.fecha_final},domProps:{"value":(_vm.detalle.fecha_cargue)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "fecha_cargue", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cargo Solicitante")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.cargo_solicitante),expression:"detalle.cargo_solicitante"}],staticClass:"form-control form-control-sm",class:_vm.$v.detalle.cargo_solicitante.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"CARGO SOLICITANTE","type":"text"},domProps:{"value":(_vm.detalle.cargo_solicitante)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "cargo_solicitante", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Dependencia")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.dependencia),expression:"detalle.dependencia"}],staticClass:"form-control form-control-sm",class:_vm.$v.detalle.dependencia.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"DEPENDENCIA","type":"text"},domProps:{"value":(_vm.detalle.dependencia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "dependencia", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nombre Solicitante")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.nombre_solicitante),expression:"detalle.nombre_solicitante"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.nombre_solicitante.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"NOMBRE SOLICITANTE","type":"text"},domProps:{"value":(_vm.detalle.nombre_solicitante)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "nombre_solicitante", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("# Contacto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.numero_contacto),expression:"detalle.numero_contacto"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.numero_contacto.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"# CONTACTO","type":"number"},domProps:{"value":(_vm.detalle.numero_contacto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "numero_contacto", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tipo Medición Origen")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.tipo_medicion_origen),expression:"detalle.tipo_medicion_origen"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.tipo_medicion_origen.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"TIPO MEDICIÓN ORIGEN","type":"text"},domProps:{"value":(_vm.detalle.tipo_medicion_origen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "tipo_medicion_origen", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tipo Medición Destino")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.tipo_medicion_destino),expression:"detalle.tipo_medicion_destino"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.tipo_medicion_destino.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"TIPO MEDICIÓN DESTINO","type":"text"},domProps:{"value":(_vm.detalle.tipo_medicion_destino)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "tipo_medicion_destino", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tipo Tanque")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.tipo_tanque),expression:"detalle.tipo_tanque"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.tipo_tanque.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"TIPO TANQUE","type":"text"},domProps:{"value":(_vm.detalle.tipo_tanque)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "tipo_tanque", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tipo Vehículo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.tipo_vehiculo),expression:"detalle.tipo_vehiculo"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.tipo_vehiculo.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"TIPO VEHÍCULO","type":"text"},domProps:{"value":(_vm.detalle.tipo_vehiculo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "tipo_vehiculo", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Unidad Medida")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.unidad_medida),expression:"detalle.unidad_medida"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.unidad_medida.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"UNIDAD MEDIDA","type":"text"},domProps:{"value":(_vm.detalle.unidad_medida)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "unidad_medida", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Descripción Destino")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.desc_destino),expression:"detalle.desc_destino"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.desc_destino.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"DESCRIPCIÓN DESTINO","type":"text"},domProps:{"value":(_vm.detalle.desc_destino)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "desc_destino", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Descripción Origen")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.desc_origen),expression:"detalle.desc_origen"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.desc_origen.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"DESCRIPCIÓN ORIGEN","type":"text"},domProps:{"value":(_vm.detalle.desc_origen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "desc_origen", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Descripción Material")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.desc_material),expression:"detalle.desc_material"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.desc_material.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"DESCRIPCIÓN MATERIAL","type":"text"},domProps:{"value":(_vm.detalle.desc_material)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "desc_material", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Api")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.api),expression:"detalle.api"}],staticClass:"form-control form-control-sm",class:_vm.$v.detalle.api.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"API","type":"number"},domProps:{"value":(_vm.detalle.api)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "api", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Vol. Bruto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.vol_bruto),expression:"detalle.vol_bruto"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.vol_bruto.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"VOL. BRUTO","type":"number"},domProps:{"value":(_vm.detalle.vol_bruto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "vol_bruto", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("# Vehículos")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.num_vehiculos),expression:"detalle.num_vehiculos"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.num_vehiculos.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"placeholder":"# VEHÍCULOS","type":"number"},domProps:{"value":(_vm.detalle.num_vehiculos)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "num_vehiculos", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("% Bsw")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.porc_bsw),expression:"detalle.porc_bsw"}],staticClass:"form-control form-control-sm pl-2",class:_vm.$v.detalle.porc_bsw.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"BSW","type":"number"},domProps:{"value":(_vm.detalle.porc_bsw)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "porc_bsw", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Observaciones")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.detalle.observaciones),expression:"detalle.observaciones"}],staticClass:"form-control form-control-sm pl-2",attrs:{"placeholder":"OBSERVACIONES"},domProps:{"value":(_vm.detalle.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detalle, "observaciones", $event.target.value)}}})])])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[(!_vm.$v.detalle.$invalid)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveDetalle()}}},[_vm._v(" Guardar ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }