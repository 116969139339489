<template>
  <div class="modal fade" id="modal_edit_detalle">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-frontera pt-2 pb-2">
          <h4 class="modal-title text-white">
            Editar Detalle Nominación
          </h4>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            id="close-modal"
            ref="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <!-- Ruta regalías -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Ruta regalías</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  label="nombre"
                  placeholder="Ruta"
                  v-model="ruta"
                  :options="listasForms.rutasRegalias"
                  @input="selectRuta()"
                  :class="
                    $v.detalle.ruta_regalias_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                </v-select>
              </div>
            </div>

            <!-- Producto Líquido -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Producto Líquido</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  label="nombre"
                  placeholder="Producto Líquido"
                  v-model="producto"
                  :options="listasForms.prodLiquidos"
                  @input="selectProducto()"
                  :class="
                    $v.detalle.producto_liquido_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                </v-select>
              </div>
            </div>

            <!-- Fecha Solicitud -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Fecha Solicitud</label>
                <input
                  class="form-control form-control-sm pl-2"
                  type="date"
                  v-model="detalle.fecha_solicitud"
                  :class="
                    $v.detalle.fecha_solicitud.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :min="$parent.form.fecha_inicial"
                  :max="$parent.form.fecha_final"
                />
              </div>
            </div>
            <!-- Fecha Cargue -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Fecha Cargue</label>
                <input
                  class="form-control form-control-sm pl-2"
                  type="date"
                  v-model="detalle.fecha_cargue"
                  :class="
                    $v.detalle.fecha_cargue.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :min="$parent.form.fecha_inicial"
                  :max="$parent.form.fecha_final"
                />
              </div>
            </div>
            <!-- Cargo Solicitante -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Cargo Solicitante</label>
                <input
                  placeholder="CARGO SOLICITANTE"
                  type="text"
                  class="form-control form-control-sm"
                  v-model="detalle.cargo_solicitante"
                  :class="
                    $v.detalle.cargo_solicitante.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>

            <!-- Dependencia -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Dependencia</label>
                <input
                  placeholder="DEPENDENCIA"
                  type="text"
                  class="form-control form-control-sm"
                  v-model="detalle.dependencia"
                  :class="
                    $v.detalle.dependencia.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Nombre Solicitante -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Nombre Solicitante</label>
                <input
                  placeholder="NOMBRE SOLICITANTE"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.nombre_solicitante"
                  :class="
                    $v.detalle.nombre_solicitante.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Número Contanto -->
            <div class="col-md-3">
              <div class="form-group">
                <label># Contacto</label>
                <input
                  placeholder="# CONTACTO"
                  class="form-control form-control-sm pl-2"
                  type="number"
                  v-model="detalle.numero_contacto"
                  :class="
                    $v.detalle.numero_contacto.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Tipo Medición Origen -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Tipo Medición Origen</label>
                <input
                  placeholder="TIPO MEDICIÓN ORIGEN"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.tipo_medicion_origen"
                  :class="
                    $v.detalle.tipo_medicion_origen.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>

            <!-- Tipo Medición Destino -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Tipo Medición Destino</label>
                <input
                  placeholder="TIPO MEDICIÓN DESTINO"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.tipo_medicion_destino"
                  :class="
                    $v.detalle.tipo_medicion_destino.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Tipo Tanque -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Tipo Tanque</label>
                <input
                  placeholder="TIPO TANQUE"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.tipo_tanque"
                  :class="
                    $v.detalle.tipo_tanque.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Tipo Vehículo -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Tipo Vehículo</label>
                <input
                  placeholder="TIPO VEHÍCULO"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.tipo_vehiculo"
                  :class="
                    $v.detalle.tipo_vehiculo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Unidad Medida -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Unidad Medida</label>
                <input
                  placeholder="UNIDAD MEDIDA"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.unidad_medida"
                  :class="
                    $v.detalle.unidad_medida.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>

            <!-- Descripción Destino -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Descripción Destino</label>
                <input
                  placeholder="DESCRIPCIÓN DESTINO"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.desc_destino"
                  :class="
                    $v.detalle.desc_destino.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>

            <!-- Descripción Origen -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Descripción Origen</label>
                <input
                  placeholder="DESCRIPCIÓN ORIGEN"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.desc_origen"
                  :class="
                    $v.detalle.desc_origen.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Descripción Material -->
            <div class="col-md-3">
              <div class="form-group">
                <label>Descripción Material</label>
                <input
                  placeholder="DESCRIPCIÓN MATERIAL"
                  class="form-control form-control-sm pl-2"
                  type="text"
                  v-model="detalle.desc_material"
                  :class="
                    $v.detalle.desc_material.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Api -->
            <div class="col-md-2">
              <div class="form-group">
                <label>Api</label>
                <input
                  placeholder="API"
                  type="number"
                  class="form-control form-control-sm"
                  v-model="detalle.api"
                  :class="$v.detalle.api.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
            </div>
            <!-- Vol. Bruto -->
            <div class="col-md-2">
              <div class="form-group">
                <label>Vol. Bruto</label>
                <input
                  placeholder="VOL. BRUTO"
                  class="form-control form-control-sm pl-2"
                  type="number"
                  v-model="detalle.vol_bruto"
                  :class="
                    $v.detalle.vol_bruto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Número Vehículos -->
            <div class="col-md-2">
              <div class="form-group">
                <label># Vehículos</label>
                <input
                  placeholder="# VEHÍCULOS"
                  class="form-control form-control-sm pl-2"
                  type="number"
                  v-model="detalle.num_vehiculos"
                  :class="
                    $v.detalle.num_vehiculos.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!-- Bsw -->
            <div class="col-md-2">
              <div class="form-group">
                <label>% Bsw</label>
                <input
                  placeholder="BSW"
                  class="form-control form-control-sm pl-2"
                  type="number"
                  v-model="detalle.porc_bsw"
                  :class="
                    $v.detalle.porc_bsw.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>

            <!-- Observaciones -->
            <div class="col-md-12">
              <div class="form-group">
                <label>Observaciones</label>
                <textarea
                  placeholder="OBSERVACIONES"
                  class="form-control form-control-sm pl-2"
                  v-model="detalle.observaciones"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveDetalle()"
            v-if="!$v.detalle.$invalid"
          >
            Guardar
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import axios from "axios";
import { required, numeric } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "NominacionRegaliasModal",
  components: {
    vSelect,
  },
  data() {
    return {
      detalle: {},
      ruta: [],
      producto: [],
      listasForms: {
        rutasRegalias: [],
        prodLiquidos: [],
      },
    };
  },
  validations: {
    detalle: {
      ruta_regalias_id: {
        required,
      },
      producto_liquido_id: {
        required,
      },
      fecha_solicitud: {
        required,
      },
      fecha_cargue: {
        required,
      },
      cargo_solicitante: {
        required,
      },
      dependencia: {
        required,
      },
      nombre_solicitante: {
        required,
      },
      numero_contacto: {
        required,
      },
      tipo_medicion_destino: {
        required,
      },
      tipo_medicion_origen: {
        required,
      },
      tipo_tanque: {
        required,
      },
      tipo_vehiculo: {
        required,
      },
      unidad_medida: {
        required,
      },
      desc_destino: {
        required,
      },
      desc_origen: {
        required,
      },
      desc_material: {
        required,
      },
      api: {
        required,
      },
      num_vehiculos: {
        required,
      },
      porc_bsw: {
        required,
      },
      vol_bruto: {
        required,
      },
    },
  },
  methods: {
    async llenarModal(detalle) {
      this.$parent.cargando = true;
      this.detalle = { ...detalle };
      this.ruta = this.detalle.ruta_regalia;
      this.producto = this.detalle.prod_liquido;
      this.listasForms.rutasRegalias = this.$parent.arrListasForm.rutasRegalias;
      this.listasForms.prodLiquidos = this.$parent.arrListasForm.prodLiquidos;
      this.$parent.cargando = false;
    },

    selectProducto() {
      this.detalle.producto_liquido_id = this.producto
        ? this.producto.id
        : null;
    },
    selectRuta() {
      this.detalle.ruta_regalias_id = this.ruta ? this.ruta.id : null;
    },

    saveDetalle() {
      axios({
        method: this.$parent.metodo,
        url: "/api/hidrocarburos/nominacionesRegalias/saveDetalle",
        data: this.detalle,
      })
        .then((response) => {
          this.$refs.closeModal.click();
          this.$swal({
            icon: "success",
            title: "El detalle se actualizó corretamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
